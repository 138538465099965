"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lineupjs_1 = require("lineupjs");
require("./MyImageCellRenderer.css");
var MyImageCellRenderer = /** @class */ (function () {
    function MyImageCellRenderer() {
        this.title = 'Scaling Image';
    }
    MyImageCellRenderer.prototype.canRender = function (col, mode) {
        return col instanceof lineupjs_1.LinkColumn && mode === lineupjs_1.ERenderMode.CELL;
    };
    MyImageCellRenderer.prototype.create = function (col) {
        return {
            template: "<div><img class=\"box-img\" src=\"\"></div>",
            update: function (n, d) {
                var missing = lineupjs_1.renderMissingDOM(n, col, d);
                var v = col.getLink(d);
                //n.title = v ? v.alt : '';
                //n.style.backgroundImage = missing || !v ? null : `url('${v.href}')`;
                var imgNode = n.firstElementChild;
                imgNode.src = v ? v.href : '';
                imgNode.alt = v ? v.alt : '';
            },
            render: function () { return null; }
        };
    };
    MyImageCellRenderer.prototype.createGroup = function () {
        return {
            template: '<div></div>',
            update: function (node, group, rows) {
            }
        };
    };
    MyImageCellRenderer.prototype.createSummary = function () {
        return {
            template: '<div></div>',
            update: function (node, hist) {
            }
        };
    };
    return MyImageCellRenderer;
}());
exports.default = MyImageCellRenderer;
